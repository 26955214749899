import { addNotificationQue, loadFireStore } from "../../handlers"

export const addComment = async ({ comment }) => {
  const firebase = await import("firebase/app")

  try {
    const collection: any = await loadFireStore("comments", null)
    await collection.add(comment)

    if (comment.pId) {
      addNotificationQue({ comment })

      // handle other users add child comments
      const snapshots = await collection.where("pId", "==", comment.pId).get()
      const docs = snapshots.docs
      docs.map(async (snapshot, id) => {
        const data = snapshot.data()
        const targetUser = data.userId

        if (comment.userId !== targetUser) {
          const userDoc = await loadFireStore("user", targetUser)
          const notifications = {
            notifications: firebase.firestore.FieldValue.arrayUnion({
              kind: "comment",
              ...comment,
            }),
          }
          await userDoc.update({
            ...notifications,
          })
        }
      })
    }
  } catch (error) {
    console.error("error - addComment", error)
    alert("Error (addComment)")
  }
}
