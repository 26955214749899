import { loadFireStore } from "../../handlers"

export const deleteComment = async ({ commentId }) => {
  try {
    const commentsDoc: any = await loadFireStore("comments", commentId)
    await commentsDoc.delete()
    alert("Comment deleted")
  } catch (error) {
    console.error("error - deletePost", error)
    alert("Error (deletePost) - " + error)
  }
}
