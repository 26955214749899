import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import * as React from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { navigate } from "../../../../plugins/gatsby-plugin-intl-with-root"
import { COURSES, NAVIGATE } from "../../../constants"
import { selectState, userState } from "../../../store"
import { AnimateByFrom } from "./animate-by-from"

export function Breadcrumbs({ current, postList, location }) {
  const theme = useTheme()
  const user = useRecoilValue(userState)
  const [select, setSelect] = useRecoilState(selectState)
  const courseNavigateOptions = COURSES.filter(
    course => course.status === "public"
  ).map(course => ({
    ...course,
    label: course.title,
  }))
  const currentCourse = findArrayValue(
    courseNavigateOptions,
    current.frontmatter.course
  )
  const levelNavigateOptions =
    currentCourse >= 0 && courseNavigateOptions[currentCourse].levels
  const postNavigateOptions = postList.edges.map((child, id) => {
    const $access = child.node.frontmatter.access
    const onlyUser = $access === "userOnly" && !user.email
    const onlyMember = $access === "memberOnly" && !user.member
    const blockContent = onlyUser || onlyMember // only $access === "public" contents will be visible to all
    return {
      label: child.node.frontmatter.title,
      originalPath: child.node.fields.originalPath,
      disabled: blockContent,
      endEnhancer: blockContent && (
        <System.Icons.Lock
          width={theme.sizing.scale600}
          color={theme.colors.contentTertiary}
        />
      ),
    }
  })

  if (currentCourse >= 0)
    return (
      <System.Breadcrumbs>
        <System.Select
          options={courseNavigateOptions}
          active={currentCourse}
          onChange={value => {
            const courseSlug = courseNavigateOptions[value].key
            const lastPost =
              user.progress &&
              user.progress[courseSlug] &&
              user.progress[courseSlug].last
            let postUrl
            if (lastPost) postUrl = `/${courseSlug}${lastPost}`
            else
              postUrl = `/${courseSlug}/${courseNavigateOptions[value].levels[0].key}/introduction`
            navigate(postUrl, { state: { from: NAVIGATE.COURSE } })
          }}
          isOpen={select === NAVIGATE.COURSE}
          animate
          onClickOutside={() => select === NAVIGATE.COURSE && setSelect("")}
          onClick={() => setSelect(NAVIGATE.COURSE)}
          overrides={{
            Wrap: {
              [theme.mediaQuery.large]: {
                width: "12vw",
              },
            },
          }}
        />
        <AnimateByFrom from={NAVIGATE.LEVEL} location={location}>
          <System.Select
            options={levelNavigateOptions}
            active={findArrayValue(
              levelNavigateOptions,
              current.frontmatter.type
            )}
            onChange={value =>
              navigate(
                `/${current.frontmatter.course}/${levelNavigateOptions[value].key}/introduction`,
                {
                  state: { from: NAVIGATE.LEVEL },
                }
              )
            }
            isOpen={select === NAVIGATE.LEVEL}
            animate
            onClickOutside={() => select === NAVIGATE.LEVEL && setSelect("")}
            onClick={() => setSelect(NAVIGATE.LEVEL)}
            overrides={{
              Wrap: {
                [theme.mediaQuery.large]: {
                  width: "23vw",
                },
              },
            }}
          />
        </AnimateByFrom>
        <AnimateByFrom from={NAVIGATE.POST} location={location}>
          <System.Select
            options={postNavigateOptions}
            active={current.frontmatter.order}
            onChange={value =>
              navigate(postNavigateOptions[value].originalPath, {
                state: { from: NAVIGATE.POST },
              })
            }
            overrides={{
              Wrap: {
                minWidth: "300px",
                [theme.mediaQuery.large]: {
                  minWidth: "initial",
                  width: "60vw",
                },
              },
              OptionList: {
                maxHeight: "300px",
                overflowY: "scroll",
              },
            }}
            isOpen={select === NAVIGATE.POST}
            animate
            onClickOutside={() => select === NAVIGATE.POST && setSelect("")}
            onClick={() => setSelect(NAVIGATE.POST)}
          />
        </AnimateByFrom>
      </System.Breadcrumbs>
    )
  else
    return (
      <System.ParagraphMedium paddingLeft="scale600" color="contentSecondary">
        not visible course
      </System.ParagraphMedium>
    )
}

function findArrayValue(arr, keyValue) {
  if (arr)
    for (let i = 0; i < arr.length; i++) if (arr[i].key === keyValue) return i
}

Breadcrumbs.defaultProps = {}
