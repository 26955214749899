import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { Wrap, Column, Title } from "./styled-components"
import { useSetRecoilState, useRecoilValue } from "recoil"
import { modalState, userState } from "../../store"
import { MODAL } from "../../constants"
import { DesktopView, MobileView } from "../../utils"
import * as Illust from "../../assets/illust"
import { useIntl } from "../../../plugins/gatsby-plugin-intl-with-root"

export const CTABecomeAMember = ({}) => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const setModal = useSetRecoilState(modalState)
  const user = useRecoilValue(userState)
  const intl = useIntl()

  return (
    <Wrap {...sharedProps}>
      <Column {...sharedProps}>
        <Title {...sharedProps}>
          {intl.formatMessage({ id: "ui.cta.become_member_continue_course" })}
        </Title>
        <System.Block height="30px" />
        <System.Button
          kind="primary"
          shape="pill"
          startEnhancer={
            <System.Icons.Arrow
              width={theme.sizing.scale300}
              color={theme.colors.contentInversePrimary}
            />
          }
          onClick={() => setModal(MODAL.BECOME_A_MEMBER)}
        >
          {intl.formatMessage({ id: "ui.cta.become_a_member" })}
        </System.Button>
        {!user.email && (
          <System.Block display="block">
            <System.Button
              kind="tertiary"
              onClick={() => setModal(MODAL.SIGN_IN)}
              overrides={{
                BaseButton: {
                  backgroundColor: "transparent",
                },
              }}
            >
              {intl.formatMessage({ id: "ui.or" })}{" "}
              {intl.formatMessage({ id: "ui.auth.sign_in" })}
            </System.Button>
          </System.Block>
        )}
      </Column>
      <Column {...sharedProps}>
        <DesktopView>
          <Illust.Lock width={434} color={theme.colors.primary} />
        </DesktopView>
        <MobileView>
          <Illust.Lock width="50%" color={theme.colors.primary} align="right" />
        </MobileView>
      </Column>
    </Wrap>
  )
}

CTABecomeAMember.defaultProps = {}
