import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import * as React from "react"
import { Iframe } from "../../../components/iframe"
import { guideBg, sizeBg } from "../../../utils"
import { Bar, FeaturedMedia, StyledGatsbyImage } from "./styled-components"
import { ViewCounter } from "./view-counter"

export function PostSummary({
  $title,
  $excerpt,
  $category,
  $gatsbyImageData,
  $featuredMotion,
  $fields,
}) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }

  return (
    <System.Block
      maxWidth={`${theme.breakpoints.xLarge}px`}
      margin="0 auto"
      display="grid"
      gridColumnGap={theme.sizing.scale700}
      overrides={{
        Block: {
          gridTemplateColumns: "1fr 2fr",
          [theme.mediaQuery.large]: {
            gridTemplateColumns: "1fr",
          },
        },
      }}
    >
      <System.Block
        backgroundColor={guideBg}
        overrides={{
          Block: {
            padding: `${theme.sizing.scale3200} ${theme.sizing.scale1200}`,
            [theme.mediaQuery.large]: {
              padding: `${theme.sizing.scale3200} ${theme.sizing.scale800}`,
            },
          },
        }}
      >
        <System.HeadingMedium>{$title}</System.HeadingMedium>
        <System.Spacing height="scale700" backgroundColor={sizeBg} />
        <System.ParagraphMedium>{$excerpt}</System.ParagraphMedium>
        <System.Spacing height="scale2400" backgroundColor={sizeBg} />
        <Bar {...sharedProps} />
        <System.Spacing height="scale400" backgroundColor={sizeBg} />
        <System.ParagraphSmall>
          {`${$category} / ${Math.round(
            $fields.readingTime.minutes
          )} min read • `}
          {/* <ViewCounter id={$fields.originalPath} /> */}
        </System.ParagraphSmall>
      </System.Block>
      <System.Block backgroundColor={guideBg} position="relative">
        <FeaturedMedia {...sharedProps}>
          {$gatsbyImageData && (
            <StyledGatsbyImage
              image={$gatsbyImageData}
              alt={`featured-image-of-${$title}`}
            />
          )}
          {$featuredMotion && <Iframe src={$featuredMotion} />}
        </FeaturedMedia>
      </System.Block>
    </System.Block>
  )
}

PostSummary.defaultProps = {}
