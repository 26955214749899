import * as React from "react"
import { useRef, useState } from "react"
import { timeStampToDate, timeGap, Log } from "../../../utils"
import { CommentForm } from "./comment-form"
import * as System from "@harborschool/lighthouse"
import { useTheme, useOnClickOutside } from "@harborschool/lighthouse"
import { Profile, ProfileImg } from "./styled-components"
import { useSetRecoilState } from "recoil"
import { modalState } from "../../../store"
import { MODAL } from "../../../constants"
import { deleteComment } from "../../../handlers/post-contents"

export function Comment({
  comment,
  childComments,
  slug,
  title,
  lang,
  member,
  userId,
}) {
  const appProps = { slug, title, lang, member, userId }

  return (
    <System.Block>
      <SingleComment comment={comment} {...appProps} />
      {childComments.length > 0 && (
        <>
          <System.Spacing height="scale600" />
          <System.FlexBox
            flexDirection="column"
            rowGap="scale600"
            paddingLeft="68px"
          >
            {childComments.map((child, id) => (
              <System.FlexItem key={id}>
                <SingleComment comment={child} {...appProps} />
              </System.FlexItem>
            ))}
          </System.FlexBox>
        </>
      )}
    </System.Block>
  )
}

function SingleComment({ comment, slug, title, lang, member, userId }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [showReplyBox, setShowReplyBox] = useState(false)
  const ref = useRef(null)
  useOnClickOutside(ref, () => setShowReplyBox(false))
  const setModal = useSetRecoilState(modalState)

  return (
    <System.Block forwardedRef={ref}>
      <System.Block display="flex">
        <Profile {...sharedProps}>
          {/* <ProfileImg
            src="https://tinyfac.es/data/avatars/8770C6F6-FA84-4526-97DC-E41AE1B552AB.jpeg"
          /> */}
          <System.LabelSmall
            overrides={{
              Block: {
                textTransform: "capitalize",
              },
            }}
          >
            {comment.name[0]}
          </System.LabelSmall>
        </Profile>
        <System.Block marginLeft="scale700">
          <System.Block>
            <System.LabelSmall>{comment.name}</System.LabelSmall>
            <System.ParagraphSmall>{comment.content}</System.ParagraphSmall>
          </System.Block>
          <System.FlexBox
            alignItems="center"
            columnGap="scale300"
            marginTop="scale100"
          >
            <System.FlexItem>
              <System.LabelXSmall
                color="contentSecondary"
                cursor="pointer"
                onClick={() => {
                  if (member) setShowReplyBox(!showReplyBox)
                  else setModal(MODAL.BECOME_A_MEMBER)
                }}
              >
                {showReplyBox ? "Cancel" : "Reply"}
              </System.LabelXSmall>
            </System.FlexItem>
            {comment.userId === userId && (
              <System.FlexItem>
                <System.LabelXSmall
                  color="contentSecondary"
                  cursor="pointer"
                  onClick={() => {
                    if (
                      confirm("Are you sure you want to delete this comment?")
                    )
                      deleteComment({ commentId: comment.id })
                  }}
                >
                  Delete
                </System.LabelXSmall>
              </System.FlexItem>
            )}

            <System.FlexItem>
              <System.ParagraphXSmall as="time" color="contentSecondary">
                {timeGap(comment.date)}
              </System.ParagraphXSmall>
            </System.FlexItem>
          </System.FlexBox>
        </System.Block>
      </System.Block>
      {showReplyBox && (
        <System.Block marginTop="scale600">
          <CommentForm
            slug={slug}
            title={title}
            lang={lang}
            parentId={comment.id}
          />
        </System.Block>
      )}
    </System.Block>
  )
}
