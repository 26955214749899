import { styled } from "styletron-react"

export const Wrap = styled("div", ({ $theme }) => {
  return {}
})

export const CommentList = styled("div", ({ $theme }) => {
  return {}
})

export const Profile = styled("div", ({ $theme }) => {
  return {
    width: $theme.sizing.scale1200,
    height: $theme.sizing.scale1200,
    position: "relative",
    background: $theme.colors.backgroundSecondary,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "1000px",
  }
})
export const ProfileImg = styled("img", ({ $theme }) => {
  return {
    width: "100%",
    height: "100%",
    position: "absolute",
  }
})
