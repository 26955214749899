import { styled } from "styletron-react"
import { motion } from "framer-motion"

export const Wrap = styled("div", ({ $theme, $kind }) => {
  return {
    height: "100%",
  }
})

export const LoadingWrap = styled(motion.div, ({ $theme, $kind }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "25%",
  }
})

export const Refresh = styled(motion.div, ({ $theme }) => {
  return {
    padding: `${$theme.sizing.scale300} ${$theme.sizing.scale600}`,
    position: "absolute",
    right: $theme.sizing.scale900,
    bottom: $theme.sizing.scale800,
    cursor: "pointer",
    color: $theme.colors.contentSecondary,
    background: $theme.colors.backgroundPrimary,
    borderRadius: "100px",
  }
})
