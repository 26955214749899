import { styled } from "styletron-react"

export const Wrap = styled("div", ({ $theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    [$theme.mediaQuery.large]: {
      flexDirection: "column",
    },
  }
})

export const Title = styled("div", ({ $theme }) => {
  return {
    color: $theme.colors.contentPrimary,
    ...$theme.typography.DisplayXSmall,
  }
})

export const Column = styled("div", ({ $theme }) => {
  return {
    padding: `0 ${$theme.sizing.scale1200}`,
  }
})
