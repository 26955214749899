import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modalState, userState } from "../../../store"
import { addComment } from "../../../handlers/post-contents"
import { useTheme } from "@harborschool/lighthouse"
import { MODAL } from "../../../constants"

export function CommentForm({ slug, title, lang, parentId = null }) {
  const user = useRecoilValue(userState)
  const theme = useTheme()
  const setModal = useSetRecoilState(modalState)

  return (
    <System.Form
      onSubmit={async (values, e) => {
        if (user.member) {
          const { content } = values
          let comment = {
            userId: user.uid,
            name: user.name,
            email: user.email,
            content,
            slug,
            title,
            lang,
            pId: parentId || null,
            date: new Date().getTime(),
          }

          await addComment({ comment })
          alert("Comment added")
          e.target.reset()
        } else setModal(MODAL.BECOME_A_MEMBER)
      }}
      formBody={
        <System.Input
          name="content"
          label=""
          placeholder="Add a comment"
          required
        />
      }
      formFooter={
        <System.Button type="submit" kind="tertiary">
          Comment
        </System.Button>
      }
      rowGap="scale0"
      overrides={{
        FormWrap: {
          textAlign: "right",
        },
        FormFooter: {
          marginTop: theme.sizing.scale300,
        },
      }}
    />
  )
}

CommentForm.defaultProps = {}
