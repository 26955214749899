import * as React from "react"
import { motion, useAnimation } from "framer-motion"
import { useTheme } from "@harborschool/lighthouse"
import { useRecoilValue } from "recoil"
import { loadingState } from "../../../store"
import { useEffect } from "react"

export function AnimateByFrom({
  children,
  from,
  location: { state: locationState },
}) {
  const theme = useTheme()
  const loading = useRecoilValue(loadingState)
  const animControl = useAnimation()
  const variants = {
    hide: {
      y: 10,
      opacity: 0,
      transition: {
        duration: 0,
      },
    },
    show: {
      y: 0,
      opacity: 1,
      transition: {
        duration: theme.animation.timing600,
        ease: theme.animation.easeInCurve,
      },
    },
  }
  const shouldAnimate = locationState && from === locationState.from
  const motionProps = {
    initial: shouldAnimate && variants.hide,
    animate: animControl,
  }
  useEffect(() => {
    async function reveal() {
      // hide it first
      await animControl.start(variants.hide)
      // show!
      animControl.start(variants.show)
    }
    loading === "loaded" && shouldAnimate && reveal()
  }, [loading])

  return <motion.div {...motionProps}>{children}</motion.div>
}

AnimateByFrom.defaultProps = {}
