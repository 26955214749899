import * as React from "react"
import { CommentList } from "./styled-components"
import { CommentForm } from "./comment-form"
import { Comment } from "./comment"
import { usePostComments } from "../../../hooks/post-contents/use-post-comments"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { modalState, userState } from "../../../store"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { MODAL } from "../../../constants"

export function Comments({ slug, title, lang }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const comments = usePostComments({ slug, lang })
  const user = useRecoilValue(userState)
  const member = user.member
  const setModal = useSetRecoilState(modalState)

  return (
    <System.Block
      overrides={{
        Block: {
          [theme.mediaQuery.large]: {
            padding: `0 ${theme.sizing.scale800}`,
          },
        },
      }}
    >
      <System.Block
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="scale900"
      >
        <System.HeadingSmall width="150px">
          Join the discussion
        </System.HeadingSmall>
        {!member && (
          <System.Button
            kind="primary"
            shape="pill"
            startEnhancer={
              <System.Icons.Arrow
                width={theme.sizing.scale300}
                color={theme.colors.contentInversePrimary}
              />
            }
            onClick={() => setModal(MODAL.BECOME_A_MEMBER)}
          >
            Become a member
          </System.Button>
        )}
      </System.Block>
      <System.Block marginBottom="scale900">
        <CommentForm slug={slug} title={title} lang={lang} />
      </System.Block>
      <CommentList>
        <System.FlexBox flexDirection="column" rowGap="scale800">
          {comments.length > 0 &&
            comments
              .filter(comment => !comment.pId)
              .map((comment, id) => {
                let childComments = []
                if (comment.id) {
                  childComments = comments
                    .filter(c => comment.id === c.pId)
                    .sort((a, b) => a.date - b.date)
                }
                return (
                  <System.FlexItem key={id}>
                    <Comment
                      childComments={childComments}
                      comment={comment}
                      slug={slug}
                      title={title}
                      lang={lang}
                      member={member}
                      userId={user.uid}
                    />
                  </System.FlexItem>
                )
              })}
        </System.FlexBox>
      </CommentList>
    </System.Block>
  )
}

Comments.defaultProps = {}
