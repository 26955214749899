import { styled } from "styletron-react"
import { motion } from "framer-motion"

export const Wrap = styled("div", ({ $theme }) => {
  return {
    position: "fixed",
    right: $theme.sizing.scale900,
    bottom: $theme.sizing.scale800,
    zIndex: $theme.zIndex.select,
  }
})

export const Item = styled("div", ({ $theme }) => {
  return {
    position: "relative",
  }
})

export const Title = styled(motion.a, ({ $theme }) => {
  return {
    textDecoration: "none",
    color: $theme.colors.contentPrimary,
    paddingLeft: $theme.sizing.scale550,
    ...$theme.typography.ParagraphXSmall,
  }
})

export const Bar = styled(motion.div, ({ $theme }) => {
  return {
    background: $theme.colors.primary,
    width: $theme.sizing.scale100,
    height: "100%",
    position: "absolute",
  }
})
