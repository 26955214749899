import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import * as React from "react"
import { useRecoilValue } from "recoil"
import { navigate } from "../../../plugins/gatsby-plugin-intl-with-root"
import { CTABecomeAMember, CTASignUp } from "../../components/cta"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useLastPost } from "../../hooks/user"
import { userState } from "../../store"
import { Log, MobileView, sizeBg } from "../../utils"
import { Breadcrumbs } from "./breadcrumbs"
import { Comments } from "./comments"
import { DebugPanel } from "./debug-panel/debug-panel"
import { PostSummary } from "./post-summary"
import { TableOfContents } from "./table-of-contents"

export default function PostLayout({
  data: { current, prev, next, navigateTo, postList },
  location,
}) {
  Log.info(`PostLayout/current`, current)
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const postInfoProps = {
    $title: current.frontmatter.title,
    $excerpt: current.frontmatter.excerpt,
    $category: current.frontmatter.category,
    $gatsbyImageData:
      current.frontmatter.featuredImg &&
      current.frontmatter.featuredImg.childImageSharp.gatsbyImageData,
    $featuredMotion: current.frontmatter.featuredMotion,
    $fields: current.fields,
  }
  useLastPost(current.fields.originalPath)
  const user = useRecoilValue(userState)
  const $access = current.frontmatter.access
  const onlyUser = $access === "userOnly" && !user.email
  const onlyMember = $access === "memberOnly" && !user.member
  const blockContent = onlyUser || onlyMember // only $access === "public" contents will be visible to all

  return (
    <Layout>
      <SEO
        title={current.frontmatter.title}
        description={current.frontmatter.excerpt}
      />
      <System.Block as="article">
        {/* table-of-contents */}
        <System.Block
          overrides={{
            Block: {
              [theme.mediaQuery.xLarge]: {
                display: "none",
              },
            },
          }}
        >
          <TableOfContents current={current} />
        </System.Block>
        {/* post-summary */}
        <System.Block
          id="post-summary-block"
          maxWidth={`${theme.breakpoints.xLarge}px`}
          margin="0 auto"
        >
          <System.Spacing height="scale1000" backgroundColor={sizeBg} />
          {/* Breadcrumbs */}
          <Breadcrumbs
            current={current}
            postList={postList}
            location={location}
          />
          <PostSummary {...postInfoProps} />
          <System.Spacing height="scale3200" backgroundColor={sizeBg} />
        </System.Block>
        <System.Block maxWidth={`${theme.breakpoints.large}px`} margin="0 auto">
          {/* post-contents */}
          <MDXRenderer $blockContent={blockContent}>{current.body}</MDXRenderer>
          {/* bottom spaces */}
          <System.Spacing height="scale4800" backgroundColor={sizeBg} />
          <System.Spacing height="scale4800" backgroundColor={sizeBg} />
          <MobileView>
            <System.Spacing height="scale4800" backgroundColor={sizeBg} />
            <System.Spacing height="scale4800" backgroundColor={sizeBg} />
          </MobileView>
        </System.Block>
      </System.Block>

      <System.Block maxWidth={`${theme.breakpoints.large}px`} margin="0 auto">
        {/* CTA */}
        {onlyUser && <CTASignUp />}
        {onlyMember && <CTABecomeAMember />}

        {/* Post Navigation */}
        {!blockContent && (
          <System.Block
            display="grid"
            gridGap={theme.sizing.scale900}
            overrides={{
              Block: {
                gridTemplateColumns: "repeat(3, 1fr)",
                [theme.mediaQuery.large]: {
                  gridTemplateColumns: "1fr",
                  padding: `0px ${theme.sizing.scale800}`,
                },
              },
            }}
          >
            {/* Empty blocks for layouts */}
            <System.Block />
            {(!next || !prev) && <System.Block />}
            {prev && (
              <System.Card
                label=""
                title={prev.frontmatter.title}
                onClick={() => navigate(prev.fields.originalPath)}
              >
                <System.CardBody>{prev.frontmatter.excerpt}</System.CardBody>
                <System.CardAction>
                  <System.Button
                    startEnhancer={
                      <System.Icons.Arrow
                        width={theme.sizing.scale300}
                        color={theme.colors.primary}
                      />
                    }
                    kind="tertiary"
                  >
                    Prev
                  </System.Button>
                </System.CardAction>
              </System.Card>
            )}
            {next && (
              <System.Card
                label=""
                title={next.frontmatter.title}
                onClick={() => navigate(next.fields.originalPath)}
              >
                <System.CardBody>{next.frontmatter.excerpt}</System.CardBody>
                <System.CardAction>
                  <System.Button
                    startEnhancer={
                      <System.Icons.Arrow
                        width={theme.sizing.scale300}
                        color={theme.colors.primary}
                      />
                    }
                    kind="tertiary"
                  >
                    Next
                  </System.Button>
                </System.CardAction>
              </System.Card>
            )}
          </System.Block>
        )}
        <System.Spacing height="scale4800" backgroundColor={sizeBg} />

        {/* Comments */}
        {!onlyUser && !onlyMember && (
          <>
            <Comments
              slug={current.fields.originalPath}
              title={current.frontmatter.title}
              lang={current.frontmatter.lang}
            />
            <System.Spacing height="scale4800" backgroundColor={sizeBg} />
            <System.Spacing height="scale4800" backgroundColor={sizeBg} />
          </>
        )}
      </System.Block>

      {/* debug panel */}
      {/* {process.env.PRODUCTION !== "true" && <DebugPanel />} */}
    </Layout>
  )
}

PostLayout.defaultProps = {}

export const pageQuery = graphql`
  query PostQuery(
    $current: Int!
    $prev: Int!
    $next: Int!
    $type: String!
    $language: String!
    $navigateTo: [String!]
  ) {
    current: mdx(
      frontmatter: {
        order: { eq: $current }
        type: { eq: $type }
        lang: { eq: $language }
      }
    ) {
      ...postFields
    }
    prev: mdx(
      frontmatter: {
        order: { eq: $prev }
        type: { eq: $type }
        lang: { eq: $language }
      }
    ) {
      ...postPreviewFields
    }
    next: mdx(
      frontmatter: {
        order: { eq: $next }
        type: { eq: $type }
        lang: { eq: $language }
      }
    ) {
      ...postPreviewFields
    }
    navigateTo: allMdx(
      filter: {
        frontmatter: { lang: { eq: $language }, slug: { in: $navigateTo } }
      }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          ...postPreviewFields
        }
      }
    }
    postList: allMdx(
      filter: { frontmatter: { lang: { eq: $language }, type: { eq: $type } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          ...postListFields
        }
      }
    }
  }
`
