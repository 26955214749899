import * as React from "react"
import * as System from "@harborschool/lighthouse"
import { useTheme } from "@harborschool/lighthouse"
import { Wrap, Column, Title } from "./styled-components"
import { useSetRecoilState } from "recoil"
import { modalState } from "../../store"
import { MODAL } from "../../constants"
import { DesktopView, MobileView } from "../../utils"
import * as Illust from "../../assets/illust"

export const CTASignUp = ({}) => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const setModal = useSetRecoilState(modalState)

  return (
    <Wrap {...sharedProps}>
      <Column {...sharedProps}>
        <Title {...sharedProps}>Sign Up first to Continue the Course</Title>
        <System.Block height="30px" />
        <System.Button
          kind="primary"
          shape="pill"
          startEnhancer={
            <System.Icons.Arrow
              width={theme.sizing.scale300}
              color={theme.colors.contentInversePrimary}
            />
          }
          onClick={() => setModal(MODAL.SIGN_IN)}
        >
          Sign Up
        </System.Button>
        <System.Block display="block">
          <System.Button
            kind="tertiary"
            onClick={() => setModal(MODAL.SIGN_IN)}
            overrides={{
              BaseButton: {
                backgroundColor: "transparent",
              },
            }}
          >
            or Sign In
          </System.Button>
        </System.Block>
      </Column>
      <Column {...sharedProps}>
        <DesktopView>
          <Illust.Lock width={434} color={theme.colors.primary} />
        </DesktopView>
        <MobileView>
          <Illust.Lock width="50%" color={theme.colors.primary} align="right" />
        </MobileView>
      </Column>
    </Wrap>
  )
}

CTASignUp.defaultProps = {}
