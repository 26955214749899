import { styled } from "styletron-react"
import { GatsbyImage } from "gatsby-plugin-image"

export const Bar = styled("div", ({ $theme }) => {
  return {
    width: $theme.sizing.scale2400,
    height: $theme.sizing.scale0,
    background: $theme.colors.backgroundTertiary,
  }
})

export const FeaturedMedia = styled("div", ({ $theme }) => {
  let gapBetweenWindow = `(100vw - ${$theme.breakpoints.xLarge}px)/2`

  return {
    height: "450px",
    background: $theme.colors.backgroundTertiary,
    position: "absolute",
    top: "50%",
    transform: "translateY(-50%)",
    width: `calc(100% + ${gapBetweenWindow} - ${$theme.sizing.scale1200})`,
    left: $theme.sizing.scale1200,
    [$theme.mediaQuery.xLarge]: {
      width: `calc(100% - ${$theme.sizing.scale1200})`,
    },
    [$theme.mediaQuery.large]: {
      position: "relative",
      height: "45vw",
      left: $theme.sizing.scale800,
      width: `calc(100% - ${$theme.sizing.scale800})`,
    },
  }
})

export const StyledGatsbyImage = styled(GatsbyImage, {
  height: "100%",
})
