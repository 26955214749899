import * as React from "react"
import { useState, useContext } from "react"
import * as System from "@harborschool/lighthouse"
import { Wrap, LoadingWrap, Refresh } from "./style-components"
import { AnimatePresence, motion } from "framer-motion"
import { useTheme } from "@harborschool/lighthouse"

export const Iframe: React.FC<IframeProps> = ({
  src,
  hideRefresh,
  ...props
}) => {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const [loaded, setLoaded] = useState(false)
  const [count, setCount] = useState(0)
  const defaultTransition = {
    ease: theme.animation.easeInCurve,
    duration: theme.animation.timing200,
  }

  const variants = {
    hidden: { opacity: 0, transition: defaultTransition },
    visible: { opacity: 1, transition: defaultTransition },
  }
  const iFrameMotionProps = {
    initial: "hidden",
    animate: loaded ? "visible" : "hidden",
    variants,
  }
  const motionProps = {
    initial: "hidden",
    animate: "visible",
    exit: "hidden",
    variants,
  }
  const refresh = {
    rest: { scale: 1 },
    hover: { scale: 1.1 },
    pressed: { scale: 0.95 },
  }
  const refreshMotionProps = {
    variants: refresh,
    initial: "rest",
    whileHover: "hover",
    whileTap: "pressed",
  }
  const arrow = {
    rest: { rotate: 0 },
    hover: { rotate: 360, transition: { duration: 0.4 } },
  }

  return (
    <Wrap>
      <motion.iframe
        width="100%"
        height="100%"
        key={count}
        src={src}
        onLoad={() => setLoaded(true)}
        {...iFrameMotionProps}
      />
      <AnimatePresence>
        {loaded && (
          <Refresh
            onClick={() => {
              setLoaded(false)
              setCount(count + 1)
            }}
            {...sharedProps}
            {...refreshMotionProps}
          >
            <motion.svg
              variants={arrow}
              xmlns="http://www.w3.org/2000/svg"
              width={theme.sizing.scale600}
              viewBox="0 0 24 24"
              style={{
                display: "block",
              }}
            >
              <g>
                <path
                  d="M 0 0 L 24 0 L 24 24 L 0 24 Z"
                  fill="transparent"
                ></path>
                <path
                  d="M 8.286 5.259 C 11.29 3.603 15.027 4.133 17.451 6.559 L 17.465 6.573 L 19.723 8.7 L 17 8.7 L 17 11.3 L 24.3 11.3 L 24.3 4 L 21.7 4 L 21.7 6.99 L 19.275 4.707 C 16.027 1.469 11.03 0.769 7.017 2.989 C 3.004 5.209 0.943 9.815 1.96 14.286 C 2.977 18.758 6.828 22.019 11.407 22.284 C 15.985 22.55 20.187 19.757 21.715 15.433 L 19.265 14.567 C 18.122 17.8 14.979 19.888 11.555 19.687 C 8.132 19.487 5.254 17.047 4.496 13.703 C 3.738 10.358 5.283 6.916 8.285 5.259 Z"
                  fill={theme.colors.contentSecondary}
                ></path>
              </g>
            </motion.svg>
          </Refresh>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!loaded && (
          <LoadingWrap {...sharedProps} {...motionProps}>
            <System.ProgressBar value={30} infinite />
          </LoadingWrap>
        )}
      </AnimatePresence>
    </Wrap>
  )
}

export interface IframeProps {
  src?: string
  hideRefresh?: boolean
}

Iframe.defaultProps = {
  src:
    "https://codesandbox.io/embed/lighthouse-playground-0-v9zcg?fontsize=14&hidenavigation=1&theme=dark",
  hideRefresh: false,
}
