import { useEffect, useState } from "react"
import { loadFireStore } from "../../handlers"

export const usePostComments = ({ slug, lang }) => {
  const [list, setList] = useState([])

  useEffect(() => {
    async function update() {
      const collection: any = await loadFireStore("comments", null)
      collection.orderBy("date", "desc").onSnapshot(snapshot => {
        const docs = snapshot.docs
          .filter(doc => {
            const data = doc.data()
            return data.slug === slug && data.lang === lang
          })
          .map(doc => {
            return { id: doc.id, ...doc.data() }
          })
        setList(docs)
      })
    }
    update()
  }, [])

  return list
}
