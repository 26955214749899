import * as React from "react"
import { isEmpty, sizeBg } from "../../../utils"
import { Item, Title, Bar, Wrap } from "./styled-components"
import { useTheme } from "@harborschool/lighthouse"
import * as System from "@harborschool/lighthouse"
import { useRecoilValue } from "recoil"
import { veSectionState, veTOCState } from "../../../store"
import { AnimateSharedLayout, motion } from "framer-motion"

export function TableOfContents({ current }) {
  const theme = useTheme()
  const sharedProps = { $theme: theme }
  const veSection = useRecoilValue(veSectionState)
  const veTOC = useRecoilValue(veTOCState)
  let contents = current.tableOfContents

  const variants = {
    show: {
      y: "0%",
    },

    hide: {
      y: `calc(100% + ${theme.sizing.scale700})`,
    },
  }
  const defaultTransition = {
    ease: theme.animation.easeInCurve,
    duration: theme.animation.timing200,
  }

  return (
    <AnimateSharedLayout>
      <Wrap {...sharedProps}>
        <motion.div
          variants={variants}
          animate={
            veTOC.show && veSection.currentSection !== -1 ? "show" : "hide"
          }
          transition={defaultTransition}
        >
          <System.FlexBox flexDirection="column" rowGap="scale600">
            {!isEmpty(contents) &&
              contents.items.map((item, id) => {
                const active = id === veSection.currentSection
                const barMotionProps = {
                  layoutId: "bar",
                  transition: defaultTransition,
                }
                const titleMotionProps = {
                  initial: {
                    opacity: 0.3,
                  },
                  animate: {
                    opacity: active ? 1 : 0.3,
                  },
                  transition: defaultTransition,
                }
                return (
                  <System.FlexItem key={id}>
                    <Item>
                      {active && <Bar {...sharedProps} {...barMotionProps} />}
                      <Title
                        href={item.url}
                        {...sharedProps}
                        {...titleMotionProps}
                      >
                        {item.title}
                      </Title>
                    </Item>
                  </System.FlexItem>
                )
              })}
          </System.FlexBox>
        </motion.div>
      </Wrap>
    </AnimateSharedLayout>
  )
}

TableOfContents.defaultProps = {}
